import React from "react"
import App from "../components/App"
import Cards from '../components/Cards';
import Helmet from "react-helmet"


export default function Home() {
  return (
    <App>

      <Helmet>
        <title>CoDreams Health</title>
        <meta name="description" content="CoDreams Health publishes health articles that bring simple solutions for not-so-simple health conditions, like obesity, acne, hair loss, dandruff, and others. Idea is to make the information regarding diet & lifestyle based solutions available, which otherwise remains lost inside books and medical journals." />
        <meta name="keywords" content="CoDreams, health, obesity, acne, hair loss, dandruff, AKN, hair care, AMD, macular degeneration" />
        <meta property="og:title" content="CoDreams Health" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="CoDreams Health publishes health articles that bring simple solutions for not-so-simple health conditions, like obesity, acne, hair loss, dandruff, and others. Idea is to make the information regarding diet & lifestyle based solutions available, which otherwise remains lost inside books and medical journals." />
        <meta property="og:image" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content="https://codreams.org" />
        <link rel="canonical" href="https://codreams.org" />
      </Helmet>

      <div className='right-frame'>
        <Cards />
      </div>

    </App>
  )  
}
