import React from 'react';
import { Link } from 'gatsby';

function CardItem(props) {
  return (
    <>
      <div className='card'>
          <figure className='card_pic-wrap' data-category={props.label}>
            <img src={props.img} className='card_img' alt={props.title}></img>
          </figure>
        <div className='card__body'>
          <Link className='card_link' to={props.solution_path}>
            <h2 className='card__title'>{props.title}</h2>
            <p className='card__description'>{props.description}</p>
          </Link>
          <div className='card__footer'>
            <button className='card__btn'>
              <Link className='btn_link' to={props.blog_path}>
                Blog
              </Link>
            </button>
            <button className='card__btn'>
              <Link className='btn_link' to={props.solution_path}>
                Solution
              </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardItem