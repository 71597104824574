import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='wrapper'>
      <CardItem
        img='/before-after.jpg'
        title='Obesity'
        description='Rightsizing your weight'
        label='Research'
        blog_path='/article/blog_obesity'
        solution_path='/article/soln_obesity'
      />
      <CardItem
        img='/faceacne.jpg'
        title='Face Acne'
        description='Boosting immunity to cure acne'
        label='Research'
        blog_path='/article/blog_faceacne'
        solution_path='/article/soln_faceacne'
      />
      <CardItem
        img='/hairloss.jpg'
        title='Hairloss'
        description='Solving the problem at its root'
        label='Research'
        blog_path='/article/blog_hairloss'
        solution_path='/article/soln_hairloss'
      />
      <CardItem
        img='/dandruff.jpg'
        title='Dandruff'
        description='The real rootcause'
        label='Research'
        blog_path='/article/blog_dandruff'
        solution_path='/article/soln_dandruff'
      />
      <CardItem
        img='/akn.jpg'
        title='AKN'
        description='Acne behind neck'
        label='Research'
        blog_path='/article/blog_akn'
        solution_path='/article/soln_akn'
      />
      <CardItem
        img='/harmfulhaircare.jpg'
        title='Harmful hair care products'
        description='Insights on ingredients'
        label='Research'
        blog_path='/article/blog_haircare'
        solution_path='/article/soln_haircare'
      />
      <CardItem
        img='/amd.jpg'
        title='Macular Degeneration'
        description='Eye retina disease'
        label='Research'
        blog_path='/article/blog_amd'
        solution_path='/article/soln_amd'
      />
    </div>
  );
}

export default Cards;